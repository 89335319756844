import { Global, css } from '@emotion/react'
import { Link } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
import {
  ComponentPropsWithoutRef,
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react'
import { UseSiteSearchData } from 'react-datocms/use-site-search'
import { useInView } from 'react-intersection-observer'

import { BrearleyLogoShort, Seal } from '@/features/brearley-logo'
import { useMainNavContext } from '@/features/layout'
import {
  SearchButton,
  SearchResults,
  useSearchContext,
} from '@/features/search'
import { useFocusTrap } from '@/hooks/useFocusTrap'
import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { LogInLink } from './LogInLink'
import { MainNavItem } from './MainNavItem'
import { MobileNavButton } from './MobileNavButton'
import { NavList } from './NavList'

type Props = ComponentPropsWithoutRef<'nav' | 'div'> & {
  shortNav?: boolean
}

export const MainNav = ({ shortNav, ...props }: Props): JSX.Element => {
  const { datoCmsMainNav } = useStaticQuery<Queries.MainNavQuery>(
    graphql`
      query MainNav {
        datoCmsMainNav {
          navItems {
            ...MainNavItem
          }
          breakpoint
        }
      }
    `
  )
  const { navItems, breakpoint } = datoCmsMainNav || {}
  const [ref, setRef] = useState<HTMLElement | null>(null)
  // const navHeight = useElementHeight(ref)

  const {
    activeNavItemId,
    setActiveNavItemId,
    mobileNavIsOpen,
    setMobileNavIsOpen,
  } = useMainNavContext()
  const { searchIsOpen, setSearchIsOpen } = useSearchContext()

  const handleClose = () => {
    activeNavItemId && setActiveNavItemId(null)
    searchIsOpen && setSearchIsOpen(false)
  }

  const [searchData, setSearchData] = useState<UseSiteSearchData>()
  const searchButtonRef = useRef<HTMLDivElement | null>(null)

  const [dropdownRef, setDropdownRef] = useState<HTMLElement | null>(
    null
  )

  useEffect(() => {
    if (searchIsOpen) {
      setMobileNavIsOpen(false)
    }
  }, [searchIsOpen, setMobileNavIsOpen])

  useFocusTrap({
    elements: [dropdownRef, ref],
    condition: activeNavItemId !== null,
  })
  useFocusTrap({
    elements: [searchButtonRef.current],
    condition: searchIsOpen && searchData === undefined,
  })
  useFocusTrap({
    elements: [searchButtonRef.current, dropdownRef],
    condition: searchIsOpen && searchData !== undefined,
  })

  const { ref: observerRef, inView: observerInView } = useInView({
    initialInView: true,
  })
  const isScrolled = (shortNav || !observerInView) && !searchIsOpen

  const styles = {
    global: css`
      :root {
        --nav-height: calc(var(--fs-36) * 1.666 + 2 * var(--fs-16));
        --nav-height-min: calc(
          var(--fs-30) * 1.666 + 0.25 * var(--fs-16)
        );
        --nav-height-flex: var(--nav-height);
        @media (max-width: ${breakpoint}px) {
          --nav-height-min: calc(1.75 * var(--fs-16) + 2rem);
        }
        ${isScrolled &&
        css`
          --nav-height-flex: var(--nav-height-min);
        `}
      }
    `,
    scrollObserver: css`
      position: absolute;
      top: 0;
      left: 0;
      height: var(--nav-height);
      width: 100%;
      pointer-events: none;
      ${shortNav &&
      css`
        height: var(--nav-height-min);
      `}
    `,
    navContainer: css`
      position: sticky;
      top: 0;
      left: 0;
      height: var(--nav-height);
      width: 100%;
      z-index: 8;
      pointer-events: none;
      ${shortNav &&
      css`
        height: var(--nav-height-min);
      `}
    `,
    nav: css`
      pointer-events: all;
      position: absolute;
      top: 0;
      left: 0;
      background: var(--nav-background, ${colors.red});
      width: 100%;
      display: flex;
      align-items: center;
      padding: 1em var(--margin);
      box-sizing: border-box;
      gap: 1.5em;
      transition: padding 300ms ease;
      &:after {
        content: '';
        flex: 1;
        order: 2;
      }
      @media (max-width: ${breakpoint}px) {
        gap: 0;
      }
      ${isScrolled &&
      css`
        padding: 0.125em var(--margin);
      `}
    `,
    logo: css`
      flex: 0;
      font-size: var(--fs-36);
      color: #fff;
      display: flex;
      margin: 0.333em 0;
      order: 1;
      transition:
        opacity 300ms ease,
        font-size 300ms ease;
      ${mq().s} {
        ${searchIsOpen &&
        css`
          opacity: 0;
        `}
      }
      ${isScrolled &&
      css`
        font-size: var(--fs-30);
      `}
    `,
    dropdownContainer: css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      z-index: 7;
      pointer-events: none;
      transition: background-color 500ms ease;
      ${(activeNavItemId || searchIsOpen || mobileNavIsOpen) &&
      css`
        background-color: #000;
      `}
    `,
    seal: css`
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      align-self: center;
      justify-self: center;
      min-width: 33%;
      width: 24ch;
      max-width: 90vw;
      max-height: 90vh;
      opacity: 0;
      transition: opacity 500ms ease;
      pointer-events: none;
      ${(activeNavItemId || searchIsOpen || mobileNavIsOpen) &&
      css`
        opacity: 1;
      `}
    `,
    logInLink: css`
      order: 3;
      margin-right: -1.25rem;
      transition: opacity 200ms ease 200ms;
      ${searchIsOpen &&
      css`
        opacity: 0;
        transition-delay: 0ms;
      `}
      @media (max-width: ${breakpoint}px) {
        margin-right: -0.125rem;
      }
    `,
    searchButton: css`
      order: 3;
      transition: transform 300ms ease;
      @media (max-width: ${breakpoint}px) {
        margin-right: 0;
        ${searchIsOpen &&
        css`
          transform: translate3d(calc(1.5em + 2rem), 0, 0);
        `}
      }
    `,
    mobileNavButton: css`
      order: 3;
      display: none;
      @media (max-width: ${breakpoint}px) {
        display: block;
        ${searchIsOpen &&
        css`
          opacity: 0;
          pointer-events: none;
        `}
      }
    `,
    desktopNavList: css`
      display: flex;
      @media (max-width: ${breakpoint}px) {
        display: none;
      }
    `,
    mobileNavList: css`
      display: none;
      @media (max-width: ${breakpoint}px) {
        display: grid;
      }
    `,
  }
  return (
    <Fragment>
      <div
        ref={!shortNav ? observerRef : null}
        css={styles.scrollObserver}
      />
      <div css={styles.navContainer}>
        <nav
          css={styles.nav}
          ref={node => setRef(node)}
          {...props}
        >
          <Global styles={styles.global} />
          <Link
            to="/"
            css={styles.logo}
            onClick={handleClose}
          >
            <BrearleyLogoShort />
          </Link>
          <NavList
            navItems={navItems}
            searchIsOpen={searchIsOpen}
            breakpoint={breakpoint}
            css={styles.desktopNavList}
          />
          <LogInLink css={styles.logInLink} />
          <SearchButton
            css={styles.searchButton}
            onDataUpdate={setSearchData}
            ref={searchButtonRef}
          />
          <MobileNavButton
            css={styles.mobileNavButton}
            tabIndex={searchIsOpen ? -1 : 0}
          />
        </nav>
      </div>
      <div
        css={styles.dropdownContainer}
        {...props}
      >
        <Seal
          css={styles.seal}
          color="#ffffff1f"
        />
        <NavList
          navItems={navItems}
          searchIsOpen={searchIsOpen}
          breakpoint={breakpoint}
          css={styles.mobileNavList}
        />
        {navItems?.map((navItem, i) => (
          <MainNavItem
            data={navItem}
            breakpoint={breakpoint}
            key={i}
            ref={node => {
              if (activeNavItemId === navItem?.id) {
                setDropdownRef(node)
              }
            }}
          />
        ))}
        <SearchResults
          ref={node => {
            if (searchIsOpen && searchData !== undefined) {
              setDropdownRef(node)
            }
          }}
          data={searchData}
          isOpen={searchIsOpen}
        />
      </div>
    </Fragment>
  )
}
